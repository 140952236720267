<template>
  <div class="grg_page_noticia">

     <!-- Mensaje a mostrar si no se encuentra una noticia -->

    <section class="grg_section_boton_regreso" v-show="!existe_noticia">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div>
              <h1 class="
                  grg_font_outline grg_font_family_rb grg_title_medium
                  text-uppercase
                "
                style="
                  --color_solid: #000;
                  --color_outline: #000;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                ">{{mensaje_existe_noticia}} 404</h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_boton_regreso">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div>
              <span class="grg_font_family_rb btn grg botom_custom"
                ><router-link to="/noticias" style="color: #bd203b"
                  >Regresar al blog</router-link
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>

   

    <section class="grg_section_noticia_completa">
      <div class="container">
        <div class="row">
          <div
            class="col-sm-12"
            v-for="(entrada, index) of entrada_individual"
            :key="index"
          >
            <div>
              <h1
                class="
                  grg_font_outline grg_font_family_rb grg_title_extra_small
                  text-uppercase
                "
                style="
                  --color_solid: #000;
                  --color_outline: #000;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                "
              >
                {{ entrada.titulo }}
              </h1>
              <div class="grg_font_family_rr grg_text_extra_small">
                <p>{{ entrada.fecha_creacion }}</p>
              </div>
              <div>
                <h5 class="grg_font_family_rr grg_text_small">
                  {{ entrada.descripcion }}
                </h5>
              </div>
            </div>
            <div style="padding: 20px 0 0">
              <SliderNoticias
                v-show="mostrarSlider"
                :sliders="entrada.slider"
              ></SliderNoticias>
              <img
                v-show="!mostrarSlider"
                class="img-fluid rounded-sm"
                width="100%"
                :src="obtenerImagenesBlog(entrada.imagen)"
                alt=""
              />
            </div>
            <div class="ql-editor" style="padding: 20px 0 0">
              <h5
                class="
                  grg_font_family_rr
                  grg_text_small
                  grg_container_noticia_individual
                "
                style="line-height: 1.5"
                v-html="entrada.contenido"
              ></h5>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_boton_regreso" v-show="existe_noticia">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div>
              <span class="grg_font_family_rb btn grg botom_custom"
                ><router-link to="/noticias" style="color: #bd203b"
                  >Regresar al blog</router-link
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="grg_section_te__podria_interesar">
      <div class="container grg_container_noticias_prioridad_3">
        <div class="row">
          <div class="col-sm-12">
            <div>
              <h1
                class="
                  grg_font_outline grg_font_family_rb grg_text_big
                  text-uppercase
                "
                style="
                  --color_solid: #000;
                  --color_outline: #000;
                  --pos_x: 0.05em;
                  --pos_y: -0.05em;
                "
              >
                Te podría interesar
              </h1>
            </div>
            <div>
              <div class="container">
                <div class="row">
                  <div
                    class="col-sm-12 col-md-6 col-lg-4 col-xl-4"
                    v-for="(slider, index) of slider_noticias_prioridad_3"
                    :key="index"
                  >
                    <b-card
                      :title="slider.titulo"
                      :img-src="obtenerImagenesBlog(slider.imagen)"
                      img-alt="Image"
                      img-top
                      tag="article"
                      class="mb-2 grg_article_card_noticia"
                    >
                      <b-card-text v-html="slider.descripcion"> </b-card-text>
                      <div style="display: flex; justify-content: center">
                        <a
                          style="
                            background-color: transparent;
                            color: #bd203b;
                            border: 1px solid #bd203b;
                            border-radius: 50px;
                            padding: 0.3rem 2.5rem;
                          "
                          class="grg_text_extra_small grg_font_family_rb botom_custom"
                          :href="
                            $router.resolve({
                              name: 'Noticia',
                              params: { nombre_entrada: slider.nombre_entrada, id_entrada: slider.id_entrada},
                            }).href
                          "
                          >Ver más</a
                        >
                        <!-- <router-link style="background-color: transparent;color: #BD203B;border: 1px solid #BD203B;border-radius: 50px;padding: .3rem 2.5rem;" class="grg_text_extra_small grg_font_family_rb" :to="{name : 'Noticia', params: {id: slider.id_entrada}}">Ver más</router-link> -->
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <NoticiaComponente></NoticiaComponente> -->
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import SliderNoticias from "@/components/SliderNoticias.vue";
export default {
  name: "Noticia",
  components: {
    SliderNoticias,
  },
  data() {
    return {
      slider_noticias_prioridad_3: [],
      entrada_individual: [],
      sliders: [
        {
          id: 1,
          posicion: 0,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/carruselbeer.jpg",
          logo: "",
          width_logo: "",
          descripcion:
            "Al cuarto trimestre del 2019, la compañía mexicana reportó un total de unidades en operación de 800, contando con 526,195 metros cuadrados de piso de venta y 45,978 asientos.",
        },
        {
          id: 2,
          posicion: 1,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/carruselfarolito.jpg",
          logo: "",
          width_logo: "",
          descripcion:
            "En mayo del 2016, Grupo Gigante dio a conocer que concretó la compra de seis sucursales del restaurante de la marca Wings mismas que se ubicaban en Barranca, Fray Servando",
        },
        {
          id: 3,
          posicion: 2,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/carruselpanda.jpg",
          logo: "",
          width_logo: "",
          descripcion:
            "Al cuarto trimestre del 2019, la compañía mexicana reportó un total de unidades en operación de 800, contando con 526,195 metros cuadrados de piso de venta y 45,978 asientos.",
        },
        {
          id: 4,
          posicion: 3,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/carruselshake.jpg",
          logo: "",
          width_logo: "",
          descripcion:
            "En mayo del 2016, Grupo Gigante dio a conocer que concretó la compra de seis sucursales del restaurante de la marca Wings mismas que se ubicaban en Barranca, Fray Servando",
        },
        {
          id: 5,
          posicion: 4,
          nombre: "",
          imagen: "/grg-admin/grg_sliders/carruseltoks.jpg",
          logo: "",
          width_logo: "",
          descripcion:
            "Al cuarto trimestre del 2019, la compañía mexicana reportó un total de unidades en operación de 800, contando con 526,195 metros cuadrados de piso de venta y 45,978 asientos.",
        },
      ],
      slider_tamano: null,
      mostrarSlider: false,
      seoTitulo : '',
      seoDescripcion:'',
      seoImagen: '',
      existe_noticia: true,
      mensaje_existe_noticia: '',
    };
  },
  methods: {
    ...mapMutations(["grgFuncionChangeTransparencia"]),
    obtenerImagenesBlog(imagen) {
      if (imagen != "") {
        return this.$baseURL + imagen;
      }
    },
    get_entradas_public_aleatorias(id_entrada_visualizando) {
      // console.log(id_entrada_visualizando)
      const baseURI =
        this.$baseURL +
        "grg-admin/index.php/entrada/get_entradas_public_aleatorias";
      this.$http
        .get(
          baseURI,
          {
            params: {
              token: localStorage.getItem("token"),
              id_entrada: id_entrada_visualizando,
            },
          },
          { "Access-Control-Allow-Origin": "*" }
        )
        .then((res) => {
          // console.log(res.data);
          var index = 0;
          // console.log(res.data.NoticiasPrioridad1);
          res.data.noticias.forEach((element) => {
            element.posicion = index;
            index++;
            element.id = index;
          });
          this.slider_noticias_prioridad_3 = res.data.noticias;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getEntrada() {
      const baseURI = this.$baseURL + "grg-admin/index.php/entrada/get_entrada";
      this.$http
        .get(
          baseURI,
          {
            params: {
              token: localStorage.getItem("token"),
              nombre_entrada: this.$route.params.nombre_entrada,
            },
          },
          { "Access-Control-Allow-Origin": "*" }
        )
        .then((res) => {
          if(res.data.entrada.length > 0){
            res.data.entrada.forEach((element) => {
            var contador = 0;
            element.slider.forEach((element1) => {
              element1.posicion = contador;
              contador++;
              element1.id = contador;
              element1.imagen = element1.imagen_slider;
              element1.descripcion = element1.desc_imagen;
            });
          });
          // res.data.entrada.forEach(element =>{
          //     element.slider = this.sliders;
          // })
          this.entrada_individual = res.data.entrada;
          this.seoTitulo = this.entrada_individual[0].titulo;
          this.seoDescripcion = this.entrada_individual[0].descripcion;
          this.seoImagen = this.entrada_individual[0].imagen;
          this.slider_tamano = res.data.entrada[0].slider;

          // console.log(this.slider_tamano.length);
          if (this.slider_tamano.length != 0) {
            this.mostrarSlider = true;
          } else {
            this.mostrarSlider = false;
          }
          this.get_entradas_public_aleatorias(this.entrada_individual[0].id_entrada);
          }
          else{
            console.log(res.data.message)
            this.mensaje_existe_noticia= res.data.message;
            this.existe_noticia = false;
            this.get_entradas_public_aleatorias(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    activarSlider() {
      $("#grg_slider_nuestras_noticias").carousel();
    },
  },
  created() {
    this.grgFuncionChangeTransparencia(false);
  },
  mounted() {
    this.getEntrada();
    this.activarSlider();
  },
  metaInfo() {
    if(!this.existe_noticia){
      this.seoTitulo = "No se encontro la noticia"
    }
    return {
      title: `GRG | ${this.seoTitulo}`,
      meta: [
        {
          name: "description", content: this.seoDescripcion,
        },
        { 
          property: "og:title", content: `GRG | ${this.seoTitulo}`
        },
        { 
          property: "og:site_name", content: this.$baseURL,
        },
        {
          property: "og:description", content: this.seoDescripcion,
        },
        { 
          property: "og:type", content: "website" 
        },
        {
          property: "og:url", content: this.$baseURL,
        },
        {
          property: "og:image", content: this.$baseURL + this.seoImagen,
        },
      ],
    };
  },
  // watch: {
  //   $route: {
  //     immediate: true,
  //     handler(to, from) {
  //       document.title = to.meta.title || "GRG | Noticia";
  //     },
  //   },
  // },
};
</script>

<style lang="css">
.ql-video {
  width: 100%;
  height: 500px;
}
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
</style>

<style scoped>
.row {
    margin: 0 -15px;
}
.botom_custom:hover{
  background-color: #bd203b !important;
}
.botom_custom:hover a{
  color:#fff !important;
}
.botom_custom:hover{
  color:#fff !important;
}
.botom_custom {
  background-color: transparent;
  color: #bd203b;
  border: 1px solid #bd203b;
  border-radius: 50px;
}
.grg_article_card_noticia {
  height: 100%;
}
.grg_section_boton_regreso div {
  padding-bottom: 0px !important;
}

@media (min-width: 1200px) {
}
@media (min-width: 992px) {
  /* .grg_container_noticias_prioridad_3 .card-title{
        min-height: 90px;
    } */
  .grg_container_noticias_prioridad_3 .card-text {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
}
@media (min-width: 576px) {
}

@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
}
@media (max-width: 767.98px) {
}
@media (max-width: 575.98px) {
}
</style>